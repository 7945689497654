<template>
	<v-container fluid>
		<v-row>
			<v-col v-if="memories.length < 1 && !appLoading" cols="12" lg="6" xl="4" class="mx-auto">
				<r-no-memory/>
			</v-col>
			<v-col v-else :cols="grid_view ? 6 : 12" :sm="grid_view ? 4 : 6" md="4" lg="3" v-for="(memory, i) in memories">
				<r-memory-tile
					:thumbnail="memory.thumbnail"
					:title="memory.title"
					:shared="memory.shared"
					:curated="memory.curated"
					:to="{name: 'ShowMemory', params: {id: memory.id}}"
					:icon="memory.icon"
				></r-memory-tile>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {sync} from "vuex-pathify";

export default {
	name: 'Year',
	metaInfo() {
		return {
			title: this.year,
		}
	},
	props: ['year'],
	computed: {
		title: sync('app/title'),
		grid_view: sync('app/grid_view'),
	},
	mounted() {
		this.getYear()
		this.title = this.year
	},
	data: () => ({
		memories: [],
	}),
	methods: {
		getYear() {
			this.appLoading = true
			this.$api.memories.year(this.year)
				.then(response => {
					this.memories = response.data
				})
				.catch(error => console.log(error))
				.finally(() => this.appLoading = false)
		},
		thumbnail(memory){
			console.log(memory.thumbnail);
			return memory.thumbnail
		}
	}
}
</script>
